var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "select-company-starting-point" } },
    [
      _vm._m(0),
      _c("stage-cards", { staticClass: "cards", attrs: { cards: _vm.cards } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("h1", { staticClass: "header-text" }, [
        _vm._v("\n      What stage is your new business at?\n    "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }